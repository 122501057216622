<template>
  <div class="table-column">
    <div class="table-with-header">
      <div class="d-flex justify-space-between align-center">
        <h3>{{ $t(row?.keys[0]) }}</h3>
        <span>
          <ColorPicker
            v-if="disbaled"
            v-model="row.card_color_code"
            @change="changeCardColor(row)"
          />
        </span>
      </div>
      <v-data-table
        :loading="loading"
        :headers="headers[row?.keys[0]]"
        :items="items"
        class="elevation-1"
        :style="{ 'background-color': row.card_color_code }"
        hide-default-footer
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    ColorPicker: () => import("@/components/base/ColorPicker.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disbaled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    product: {
      type: String,
      default() {
        return "";
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      headers: {
        mostIdleVehicles: [
          {
            text: this.$t("chassis_no"),
            align: "left",
            value: "chassis_no",
            sortable: false,
          },
          {
            text: this.$t("driver_name"),
            align: "left",
            value: "customer_name",
            // width: "10%",
            sortable: false,
          },
          {
            text: this.$t("idle_time"),
            align: "left",
            value: "total_idle_time_in_seconds",
            sortable: false,
          },
        ],
        mostOverSpeedingVehicles: [
          {
            text: this.$t("chassis_no"),
            align: "left",
            value: "chassis_no",
            sortable: false,
          },
          {
            text: this.$t("driver_name"),
            align: "left",
            value: "customer_name",
            // width: "10%",
            sortable: false,
          },
          {
            text: this.$t("speed"),
            align: "left",
            value: "total_max_speed",
            sortable: false,
          },
        ],
      },
    };
  },
  methods: {
    changeCardColor(row) {
      const params = {
        section: "section_3",
        user_card_id: row.id,
        card_color_code: row.card_color_code,
      };
      this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>


.table-column
  width: 100%
  .table-with-header
      margin-bottom: 1rem
// for Data Table Header and rows
:deep .v-data-table__wrapper
  //width: 420px
  background: inherit !important
  table
    width: 100%
    // border-collapse: collapse
    // table-layout: fixed
  .v-data-table-header
    background: #EAECF0 !important
  // tbody
  //   tr
  //     td
  //       width: 100px  !important
  //       text-wrap: wrap !important
  //       word-wrap: break-word !important
</style>
